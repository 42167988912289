import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { useIntl } from "gatsby-plugin-intl";
import Typed from 'react-typed';

import "./Home.scss"

const Home = () => {

const data = useStaticQuery(graphql`
    query {
      home: file(relativePath: { eq: "homexx.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const intl = useIntl();

  const typedArr = [
    intl.formatMessage({ id: "home.typed_item1" }),
    intl.formatMessage({ id: "home.typed_item2" }),
    intl.formatMessage({ id: "home.typed_item3" })
  ]

  return (
    <section id="home" className="slice slice bg-skew bg-skew-primary">
      <div className="container pt-lg-3 position-relative zindex-100">
        <div className="row d-flex flex-column justify-content-center">
          <div className="col-md-12 col-lg-5 mb-5 text-center text-lg-left ">
              <h1 className="my-4">{intl.formatMessage({ id: "home.title_part1" })} {" "} <br />
              <Typed
                    strings={typedArr}
                    typeSpeed={40}
                    loop
                    className="typed-color"
                />
                <br />
                {intl.formatMessage({ id: "home.title_part2" })}</h1>
              {/* <div className="opacity-8 font-size-2 mb-4">
                <p>{intl.formatMessage({ id: "home.subtitle" })}</p>
              </div> */}
              <div className="mt-3">
                <a className="btn btn-lg btn-white" href="https://www.app.cloudtransportmanager.com/#/Register">{intl.formatMessage({ id: "home.button_text" })}</a>
              </div>
          </div>
          <div className="position-lg-absolute right-lg-n10 mb-n5 mb-lg-0 col-lg-10 col-xl-10 zindex-100">
          < Img fluid={data.home.childImageSharp.fluid} loading="eager" className="rounded"/>
          </div>
        </div>
      </div>
      {/* <div className="shape-container shape-position-bottom shape-line">
          <svg width="2560px" height="100px" preserveAspectRatio="none" x="0px" y="0px" viewBox="0 0 2560 100" className=""><polygon points="2560 0 2560 100 0 100"></polygon></svg></div> */}
    </section>
  );
};

export default Home;
