import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "gatsby-plugin-intl";
import AnimatedNumber from "animated-number-react";
import $ from "jquery";

import "./Pricing.scss";

const Pricing = () => {
  

    const [standardPrice, setStandardPrice] = useState(69);
    const [checkedState, setCheckedState] = useState(false);

    const [periodText, setPeriodText] = useState("pricing.month");

    const [animatedNumberDuration] = useState(400);

    const formatValue = (value) => value.toFixed(0);

    const handleClick = ({ target }) => {
        if (target.checked) {
            // setStandardPrice((standardPrice - 7) * 12);
            setStandardPrice(744);
            setPeriodText("pricing.year");
            // target.setAttribute("checked", true);
            setCheckedState(true);
        } else {
            // setStandardPrice((standardPrice / 12) + 7);
            setStandardPrice(69);
            setPeriodText("pricing.month");
            // target.removeAttribute("checked");
            setCheckedState(false);
        }
    };

    useEffect(() => {
    // //   $('#billingSwitch').removeAttr("checked");
      window.onpopstate = () => {
        setStandardPrice(69);
      }
    });

    const intl = useIntl();

    return (
        <section id="pricing" className="page-section bg-skew bg-skew-primary5">
            <div className="container">
                <div className="row justify-content-center text-white">
                    <div className="col-12 text-center mb-6">
                        <h1 className="mb-3">
                            <FormattedMessage id="pricing.title" />
                        </h1>
                        <p className="font-size-2">
                            <FormattedMessage id="pricing.subtitle" />
                        </p>
                    </div>
                </div>

                <div class="d-flex align-items-center justify-content-center pb-5">
                    <span class="h5 text-white font-weight-normal mr-4 mt-3">
                        <FormattedMessage id="pricing.monthly" />
                    </span>
                    <div class="custom-control custom-switch switch-lg">
                        <input
                            type="checkbox"
                            class="custom-control-input"
                            id="billingSwitch"
                            onClick={handleClick}
                            checked={checkedState}
                            defaultChecked={false}
                            autocomplete="off"
                        />
                        <label class="custom-control-label text-gray font-weight-normal" for="billingSwitch"></label>
                    </div>
                    <span class="h5 text-white font-weight-normal ml-1 mt-3">
                        <FormattedMessage id="pricing.annual" />
                    </span>
                </div>

                <div className="row d-none d-lg-block">
                    <table class="table bg-white shadow pricing-table table-responsive-md mb-5">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">
                                    <span class="d-block h4 mb-3">
                                        <FormattedMessage id="pricing.item1.title" />
                                    </span>
                                    <div class="d-flex justify-content-center">
                                        <span class="h4 font-weight-normal mt-1">$</span>
                                        <span class="display-4 font-weight-normal text-positive">0</span>
                                    </div>
                                    <small class="d-block text-muted">
                                        <FormattedMessage id="pricing.item1.duration" />
                                    </small>
                                    <a
                                        href="https://www.app.cloudtransportmanager.com/#/Register"
                                        className="btn btn-sm btn-outline-positive mt-4"
                                    >
                                        <FormattedMessage id="pricing.item1.button" />
                                    </a>
                                </th>
                                <th scope="col">
                                    <span class="d-block h4 mb-3">
                                        <FormattedMessage id="pricing.item2.title" />
                                    </span>
                                    <div class="d-flex justify-content-center">
                                        <span class="h4 mt-1">$</span>
                                        <span class="display-4 font-weight-normal text-information">
                                            <AnimatedNumber
                                                value={standardPrice}
                                                duration={animatedNumberDuration}
                                                formatValue={formatValue}
                                            />
                                        </span>
                                    </div>
                                    <small class="d-block text-muted">
                                        <FormattedMessage id={periodText} />
                                    </small>
                                    <a
                                        href="https://www.app.cloudtransportmanager.com/#/Register"
                                        className="btn btn-sm btn-outline-information mt-4"
                                    >
                                        <FormattedMessage id="pricing.item2.button" />
                                    </a>
                                </th>
                                <th scope="col">
                                    <span class="d-block h4 mb-3">
                                        <FormattedMessage id="pricing.item4.title" />
                                    </span>
                                    <div class="d-flex justify-content-center">
                                        <i class="fas fa-hands-helping text-neutral custom-price"></i>
                                    </div>
                                    <a href="mailto:sales@cloudtransportmanager.com" className="btn btn-sm btn-outline-neutral mt-4">
                                        <FormattedMessage id="pricing.item4.button" />
                                    </a>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">
                                    <span
                                        className="text-dotted"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={intl.formatMessage({ id: "pricing.options.user.tooltip" })}
                                    >
                                        <FormattedMessage id="pricing.options.user.text" />
                                    </span>
                                </th>
                                <td>
                                    <span className="font-weight-bolder text-positive">1</span>
                                </td>
                                <td>
                                    <span className="font-weight-bolder text-information">1</span>
                                </td>
                                <td>
                                    <span className="font-weight-bolder text-neutral">
                                        <FormattedMessage id="pricing.item4.text" />
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <span
                                        className="text-dotted"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={intl.formatMessage({ id: "pricing.options.source.tooltip" })}
                                    >
                                        <FormattedMessage id="pricing.options.source.text" />
                                    </span>
                                </th>
                                <td>
                                    <span className="font-weight-bolder text-positive">1</span>
                                </td>
                                <td>
                                    <span className="font-weight-bolder text-information">1</span>
                                </td>
                                <td>
                                    <span className="font-weight-bolder text-neutral">
                                        <FormattedMessage id="pricing.item4.text" />
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <span
                                        className="text-dotted"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={intl.formatMessage({ id: "pricing.options.target.tooltip" })}
                                    >
                                        <FormattedMessage id="pricing.options.target.text" />
                                    </span>
                                </th>
                                <td>
                                    <span className="font-weight-bolder text-positive">1</span>
                                </td>
                                <td>
                                    <span className="font-weight-bolder text-information">2</span>
                                </td>
                                <td>
                                    <span className="font-weight-bolder text-neutral">
                                        <FormattedMessage id="pricing.item4.text" />
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <span
                                        className="text-dotted"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={intl.formatMessage({ id: "pricing.options.artifacts.tooltip" })}
                                    >
                                        <FormattedMessage id="pricing.options.artifacts.text" />
                                    </span>
                                </th>
                                <td>
                                    <span className="font-weight-bolder text-positive">1</span>
                                </td>
                                <td>
                                    <span className="font-weight-bolder text-information">10</span>
                                </td>
                                <td>
                                    <span className="font-weight-bolder text-neutral">
                                        <FormattedMessage id="pricing.item4.text" />
                                    </span>
                                </td>
                            </tr>
                            {/* <tr>
                <th scope="row">
                  <span
                    className="text-dotted"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={intl.formatMessage({ id: "pricing.options.cred.tooltip" })}
                  >
                    <FormattedMessage id="pricing.options.cred.text" />
                  </span>
                </th>
                <td>
                  <span className="font-weight-bolder text-positive">
                    <FormattedMessage id="no" />
                  </span>
                </td>
                <td>
                  <span className="font-weight-bolder text-information">
                    <FormattedMessage id="yes" />
                  </span>
                </td>
                <td>
                  <span className="font-weight-bolder text-neutral">
                    <FormattedMessage id="yes" />
                  </span>
                </td>
              </tr> */}
                        </tbody>
                    </table>
                </div>

                <div class="row row-cols-1 row-cols-md-3 d-lg-none">
                    <div class="col mb-4">
                        <div class="card h-100">
                            <div class="card-header bg-transparent border-success text-center">
                                {" "}
                                <span class="d-block h4 mb-3">
                                    <FormattedMessage id="pricing.item1.title" />
                                </span>
                                <div class="d-flex justify-content-center">
                                    <span class="h4 font-weight-normal mt-1">$</span>
                                    <span class="display-4 font-weight-normal text-positive">0</span>
                                </div>
                                <small class="d-block text-muted">
                                    <FormattedMessage id="pricing.item1.duration" />
                                </small>
                                <a
                                    href="https://www.app.cloudtransportmanager.com/#/Register"
                                    className="btn btn-sm btn-outline-positive mt-4"
                                >
                                    <FormattedMessage id="pricing.item1.button" />
                                </a>
                            </div>
                            <div class="card-body text-center py-4">
                                <p className="font-weight-bolder">
                                    <span
                                        className="text-dotted"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={intl.formatMessage({ id: "pricing.options.user.tooltip" })}
                                    >
                                        <FormattedMessage id="pricing.options.user.text" />
                                    </span>
                                    :<span className="pl-3 text-positive">1</span>
                                </p>

                                <p className="font-weight-bolder">
                                    <span
                                        className="text-dotted"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={intl.formatMessage({ id: "pricing.options.source.tooltip" })}
                                    >
                                        <FormattedMessage id="pricing.options.source.text" />
                                    </span>
                                    :<span className="pl-3 text-positive">1</span>
                                </p>

                                <p className="font-weight-bolder">
                                    <span
                                        className="text-dotted"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={intl.formatMessage({ id: "pricing.options.target.tooltip" })}
                                    >
                                        <FormattedMessage id="pricing.options.target.text" />
                                    </span>
                                    :<span className="pl-3 text-positive">1</span>
                                </p>

                                <p className="font-weight-bolder">
                                    <span
                                        className="text-dotted"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={intl.formatMessage({ id: "pricing.options.artifacts.tooltip" })}
                                    >
                                        <FormattedMessage id="pricing.options.artifacts.text" />
                                    </span>
                                    :<span className="pl-3 text-positive">1</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col mb-4">
                        <div class="card h-100">
                            <div class="card-header bg-transparent border-info text-center">
                                {" "}
                                <span class="d-block h4 mb-3">
                                    <FormattedMessage id="pricing.item2.title" />
                                </span>
                                <div class="d-flex justify-content-center">
                                    <span class="h4 mt-1">$</span>
                                    <span class="display-4 font-weight-normal text-information">
                                        <AnimatedNumber value={standardPrice} duration={animatedNumberDuration} formatValue={formatValue} />
                                    </span>
                                </div>
                                <small class="d-block text-muted">
                                    <FormattedMessage id={periodText} />
                                </small>
                                <a
                                    href="https://www.app.cloudtransportmanager.com/#/Register"
                                    className="btn btn-sm btn-outline-information mt-4"
                                >
                                    <FormattedMessage id="pricing.item2.button" />
                                </a>
                            </div>
                            <div class="card-body text-center py-4">
                                <p className="font-weight-bolder">
                                    <span
                                        className="text-dotted"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={intl.formatMessage({ id: "pricing.options.user.tooltip" })}
                                    >
                                        <FormattedMessage id="pricing.options.user.text" />
                                    </span>
                                    :<span className="pl-3 text-information">1</span>
                                </p>

                                <p className="font-weight-bolder">
                                    <span
                                        className="text-dotted"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={intl.formatMessage({ id: "pricing.options.source.tooltip" })}
                                    >
                                        <FormattedMessage id="pricing.options.source.text" />
                                    </span>
                                    :<span className="pl-3 text-information">1</span>
                                </p>

                                <p className="font-weight-bolder">
                                    <span
                                        className="text-dotted"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={intl.formatMessage({ id: "pricing.options.target.tooltip" })}
                                    >
                                        <FormattedMessage id="pricing.options.target.text" />
                                    </span>
                                    :<span className="pl-3 text-information">2</span>
                                </p>

                                <p className="font-weight-bolder">
                                    <span
                                        className="text-dotted"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={intl.formatMessage({ id: "pricing.options.artifacts.tooltip" })}
                                    >
                                        <FormattedMessage id="pricing.options.artifacts.text" />
                                    </span>
                                    :<span className="pl-3 text-information">10</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col mb-4">
                        <div class="card h-100">
                            <div class="card-header bg-transparent border-warning text-center">
                                <span class="d-block h4 mb-3">
                                    <FormattedMessage id="pricing.item4.title" />
                                </span>
                                <div class="d-flex justify-content-center">
                                    <i class="fas fa-hands-helping text-neutral custom-price"></i>
                                </div>
                                <a href="mailto:sales@cloudtransportmanager.com" className="btn btn-sm btn-outline-neutral mt-4">
                                    <FormattedMessage id="pricing.item4.button" />
                                </a>
                            </div>
                            <div class="card-body text-center py-4">
                                <p className="font-weight-bolder">
                                    <span
                                        className="text-dotted"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={intl.formatMessage({ id: "pricing.options.user.tooltip" })}
                                    >
                                        <FormattedMessage id="pricing.options.user.text" />
                                    </span>
                                    :
                                    <span className="font-weight-bolder text-neutral pl-3">
                                        <FormattedMessage id="pricing.item4.text" />
                                    </span>
                                </p>

                                <p className="font-weight-bolder">
                                    <span
                                        className="text-dotted"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={intl.formatMessage({ id: "pricing.options.source.tooltip" })}
                                    >
                                        <FormattedMessage id="pricing.options.source.text" />
                                    </span>
                                    :
                                    <span className="font-weight-bolder text-neutral pl-3">
                                        <FormattedMessage id="pricing.item4.text" />
                                    </span>
                                </p>

                                <p className="font-weight-bolder">
                                    <span
                                        className="text-dotted"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={intl.formatMessage({ id: "pricing.options.target.tooltip" })}
                                    >
                                        <FormattedMessage id="pricing.options.target.text" />
                                    </span>
                                    :
                                    <span className="font-weight-bolder text-neutral pl-3">
                                        <FormattedMessage id="pricing.item4.text" />
                                    </span>
                                </p>

                                <p className="font-weight-bolder">
                                    <span
                                        className="text-dotted"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={intl.formatMessage({ id: "pricing.options.artifacts.tooltip" })}
                                    >
                                        <FormattedMessage id="pricing.options.artifacts.text" />
                                    </span>
                                    :{" "}
                                    <span className="font-weight-bolder text-neutral pl-3">
                                        <FormattedMessage id="pricing.item4.text" />
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Pricing;
