import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { FormattedMessage, useIntl } from "gatsby-plugin-intl";

import "./Product.scss";

import aa from "../../images/stagingx.png";

const Product = () => {
  const data = useStaticQuery(graphql`
    query {
      product: file(relativePath: { eq: "stagingx.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const intl = useIntl();

  return (
    <section id="product" className="page-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 text-center mb-6">
            <h1 className="mb-3">
              <FormattedMessage id="product.title" />
            </h1>
            <p className="font-size-2">
              <FormattedMessage id="product.subtitle" />
            </p>
          </div>
        </div>

        <div className="row mb-6">
          <div className="col-12 col-md-6 col-lg-3 py-3">
            <div className="workflow-item-badge d-flex align-items-center justify-content-center">1</div>
            <div className="workflow-item d-flex align-items-center justify-content-center">
              <div className="workflow-item-body">
                <h5><FormattedMessage id="product.steps.item1.title" /></h5>
                <p className="font-size-sm"><FormattedMessage id="product.steps.item1.text" /></p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3 py-3">
            <div className="workflow-item-badge d-flex align-items-center justify-content-center">2</div>
            <div className="workflow-item d-flex align-items-center justify-content-center">
              <div className="workflow-item-body">
                <h5><FormattedMessage id="product.steps.item2.title" /></h5>
                <p className="font-size-sm"><FormattedMessage id="product.steps.item2.text" /></p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3 py-3">
            <div className="workflow-item-badge d-flex align-items-center justify-content-center">3</div>
            <div className="workflow-item d-flex align-items-center justify-content-center">
              <div className="workflow-item-body">
                <h5><FormattedMessage id="product.steps.item3.title" /></h5>
                <p className="font-size-sm"><FormattedMessage id="product.steps.item3.text" /></p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3 py-3">
            <div className="workflow-item-badge d-flex align-items-center justify-content-center">4</div>
            <div className="workflow-item d-flex align-items-center justify-content-center">
              <div className="workflow-item-body">
                <h5><FormattedMessage id="product.steps.item4.title" /></h5>
                <p className="font-size-sm"><FormattedMessage id="product.steps.item4.text" /></p>
              </div>
            </div>
          </div>
        </div>

        

        <div className="row justify-content-center pb-7">
          <div className="col">
            <div className="position-relative">
              <button
                type="button"
                className="hotspot-popover bg-primary"
                style={{ top: "35%", left: "5%" }}
                data-container="body"
                data-toggle="popover"
                data-trigger="focus"
                title=""
                data-content={intl.formatMessage({ id: "product.hootspots.item1.text" })}
                data-original-title={intl.formatMessage({ id: "product.hootspots.item1.title" })}
              ></button>
              <button
                type="button"
                className="hotspot-popover bg-primary"
                style={{ top: "55%", left: "35%" }}
                data-container="body"
                data-toggle="popover"
                data-trigger="focus"
                title=""
                data-content={intl.formatMessage({ id: "product.hootspots.item2.text" })}
                data-original-title={intl.formatMessage({ id: "product.hootspots.item2.title" })}
              ></button>
              <button
                type="button"
                className="hotspot-popover bg-primary"
                style={{ top: "38%", left: "86%" }}
                data-container="body"
                data-toggle="popover"
                data-trigger="focus"
                title=""
                data-content={intl.formatMessage({ id: "product.hootspots.item3.text" })}
                data-original-title={intl.formatMessage({ id: "product.hootspots.item3.title" })}
              ></button>
              <button
                type="button"
                className="hotspot-popover bg-primary"
                style={{ top: "20%", left: "30%" }}
                data-container="body"
                data-toggle="popover"
                data-trigger="focus"
                title=""
                data-content={intl.formatMessage({ id: "product.hootspots.item4.text" })}
                data-original-title={intl.formatMessage({ id: "product.hootspots.item4.title" })}
              ></button>

              <Img fluid={data.product.childImageSharp.fluid} loading="eager" className="shadow-with-border rounded" />
              {/* <img src={aa} className="shadow-with-border rounded" /> */}
            </div>
          </div>
        </div>

        <div className="row mb-5 mb-lg-7">
          <div className="col-12 col-md-6 col-lg-3 mb-5 mb-lg-0 text-center">
            <div className="icon rounded-circle">
              <i class="fas fa-code-branch"></i>
            </div>
            <h5 className="mt-4 mb-3">
              <FormattedMessage id="product.item1.title" />
            </h5>
            <p className="px-0 px-sm-4 px-lg-0">
              <FormattedMessage id="product.item1.subtitle" />
            </p>
          </div>
          <div className="col-12 col-md-6 col-lg-3 mb-5 mb-lg-0 text-center">
            <div className="icon rounded-circle">
              <i class="fas fa-users"></i>
            </div>
            <h5 className="mt-4 mb-3">
              <FormattedMessage id="product.item2.title" />
            </h5>
            <p className="px-0 px-sm-4 px-lg-0">
              <FormattedMessage id="product.item2.subtitle" />
            </p>
          </div>
          <div className="col-12 col-md-6 col-lg-3 mb-5 mb-lg-0 text-center">
            <div className="icon rounded-circle">
              <i className="fas fa-rocket"></i>
            </div>
            <h5 className="mt-4 mb-3">
              <FormattedMessage id="product.item3.title" />
            </h5>
            <p className="px-0 px-sm-4 px-lg-0">
              <FormattedMessage id="product.item3.subtitle" />
            </p>
          </div>
          <div className="col-12 col-md-6 col-lg-3 mb-5 mb-lg-0 text-center">
            <div className="icon rounded-circle">
              <i className="fas fa-headset"></i>
            </div>
            <h5 className="mt-4 mb-3">
              <FormattedMessage id="product.item4.title" />
            </h5>
            <p className="px-0 px-sm-4 px-lg-0">
              <FormattedMessage id="product.item4.subtitle" />
            </p>
          </div>
        </div>


      </div>
    </section>
  );
};

export default Product;
