import React from "react";
import { FormattedMessage } from "gatsby-plugin-intl";
import { Link } from "gatsby";

import "./Contact.scss";

const Contact = () => {
   return (
    <section id="contact" className="page-section bg-skew bg-skew-primary2">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 text-center mb-6">
            <h1 className="mb-3"><FormattedMessage id="contact.title" /></h1>
            <p className="font-size-2"><FormattedMessage id="contact.subtitle" /></p>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4">
            <div className="card text-center mb-5 mb-lg-6">
              <div className="px-5 pb-5">
                <div className="py-5">
                  <div className="icon text-positive mx-auto">
                  <i className="fas fa-phone"></i>
                  </div>
                </div>
                <h5 className=""><FormattedMessage id="contact.item1.title" /></h5>
                <p className="mt-2 mb-0"><FormattedMessage id="contact.item1.subtitle" /></p>
                <div className="mt-4">
                  <a href="mailto:sales@cloudtransportmanager.com" className="text-positive font-size-sm">
                  sales@cloudtransportmanager.com
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card text-center mb-5 mb-lg-6">
              <div className="px-5 pb-5">
                <div className="py-5">
                  <div className="icon text-information mx-auto">
                  <i className="fas fa-cogs"></i>
                  </div>
                </div>
                <h5 className=""><FormattedMessage id="contact.item2.title" /></h5>
  <p className="mt-2 mb-0"><FormattedMessage id="contact.item2.subtitle" /> <br/><span className="text-transparent">aaa</span></p>
                <div className="mt-4">
                  <a href="mailto:support@cloudtransportmanager.com" className="text-information font-size-sm">
                  support@cloudtransportmanager.com
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card text-center mb-5 mb-lg-6">
              <div className="px-5 pb-5">
                <div className="py-5">
                  <div className="icon text-negative mx-auto">
                  <i className="fas fa-user-friends"></i>
                  </div>
                </div>
                <h5 className=""><FormattedMessage id="contact.item3.title" /></h5>
                <p className="mt-2 mb-0"><FormattedMessage id="contact.item3.subtitle" /></p>
                <div className="mt-4">
                  <Link to="/wishlist/" className="text-negative font-size-sm">
                    <FormattedMessage id="contact.item3.goToWishlist"/>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
