import React, { useEffect } from 'react';
import { useIntl } from "gatsby-plugin-intl";
import $ from 'jquery';

import Layout from "../components/layout";
import SEO from "../components/seo";

import { Home, Product, Pricing, Contact } from "../components/Sections";

const IndexPage = () => {
  useEffect(() => {
    // code to run on component mount
    $(function () {
      $('[data-toggle="popover"]').popover();
    });

    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }, []);
  const intl = useIntl();
  return (
    <Layout>
      <SEO
        lang={intl.locale}
        keywords={[`SAP Cloud Platform Integration`, `SAP`, `Cloud Transport Manager`, `Transport`, `SCI`, `CPI`]}
      />
      <Home />
      <Product />
      <Pricing />
      <Contact />
    </Layout>
  )
}

export default IndexPage
